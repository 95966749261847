//TODO: DON'T USE THIS FILE

import 'popper.js';
import 'bootstrap';
import onInitModulesInScope from "./scripts/onInitModulesInScope";
if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./scripts/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

//not found
// import * as ajaxInclude from './scripts/ajaxInclude';
// app.modules.ajaxInclude = ajaxInclude.initInScope;

//common
// import * as ajaxForm from '@elements/ajax-form';
// app.modules.ajaxForm = ajaxForm.createInitInScope({
//     submitOnChange: true
// });

//product detail
// import * as affix from '@elements/affix';
// app.modules.affix = affix.createInitInScope({
//     mediaQuery: '(min-width: 768px)'
// });

// app.modules.affixDetailbar = affix.createInitInScope({
//         breakpoints: {
//             768: {
//                 offset: 60
//             },
//         }
//     },
//     {
//         base: '.js-affix-detail-bar',
//         placeholder: '.js-affix-detail-bar__placeholder',
//         container: '.js-affix-detail-bar__container'
//     });
// app.modules.affixFilter = affix.createInitInScope({
//         breakpoints: {
//             768: {
//                 offset: 60
//             },
//         }
//     },
//     {
//         base: '.js-affix-filter',
//         placeholder: '.js-affix-filter__placeholder',
//         container: '.js-affix-filter__container'
//     });

// import * as goToLink from '@elements/go-to-link';
// app.goToLink = goToLink.init;

// import * as consentOverlay from './scripts/cookie-consent-overlay';
// app.modules.consentOverlay = consentOverlay.initInScope;

// import * as parsley from '@elements/parsley-bootstrap-validation';
// app.modules.parsley = parsley.initInScope;

// import * as toc from '@elements/toc';
// app.toc = toc.init;
// app.modules.toc = toc.initInScope;

// import * as navbarToggle from './scripts/navbar-toggle';
// app.modules.navbarToggle = navbarToggle.initInScope;
//
// import * as meiliSearch from './scripts/meili-search';
// app.meiliSearch = meiliSearch.init();

//not found
// import * as scrollTopBtn from './scripts/scroll-top-btn';
// app.modules.scrollTopBtn = scrollTopBtn.initInScope;

// import * as affixNav from './scripts/affix-nav';
// app.modules.affixNav = affixNav.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

// import * as lazyImg from './scripts/lazy-img';
// app.modules.lazyImg = lazyImg.initInScope;
/*
import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;*/

// import * as lightbox from '@elements/lightbox';
// app.modules.lightbox = lightbox.initInScope;

// import * as checkoutCountry from './scripts/checkout-country';
// app.modules.checkoutCountry = checkoutCountry.initInScope;

// import * as cartDetail from './scripts/cart-detail';
// app.cartDetail = cartDetail.init;

// import * as recaptcha from './scripts/recaptcha';
// app.recaptcha = recaptcha.init;

// import * as productSlider from './scripts/product-slider';
// app.modules.productSlider = productSlider.initInScope;

// import * as changeModal from './scripts/change-modal';
// app.modules.changeModal = changeModal.initInScope;

// import * as fetchModal from './scripts/fetch-modal';
// app.modules.fetchModal = fetchModal.initInScope;

// import * as accordion from './scripts/accordion';
// app.modules.accordion = accordion.initInScope;


// import * as slider from '@elements/slider';
// app.modules.imgContentTeaserSlider = slider.createInitInScope('.js-img-content-teaser-slider', {
//     nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow slider__arrow--big" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
//     prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow slider__arrow--big" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
//     infinite: false,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1.2
//             }
//         },
//     ]
// });

//not found
// app.modules.simpleImgTeaserSlider = slider.createInitInScope('.js-simple-img-teaser-slider', {
//     infinite: false,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: false,
//     responsive: [
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1.4
//             }
//         },
//     ]
// });

// if(matchMedia('(max-width: 767px)').matches){
//     app.modules.teaserSujetSlider = slider.createInitInScope('.js-teaser-sujet-slider', {
//         infinite: false,
//         slidesToShow: 1.4,
//         slidesToScroll: 1,
//         arrows: false,
//     });
// }

// app.modules.imgContentTeaserSliderReduced = slider.createInitInScope('.js-img-content-teaser-slider-reduced', {
//     nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow slider__arrow--big" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
//     prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow slider__arrow--big" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
//     infinite: false,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     responsive: [
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1.3
//             }
//         },
//     ]
// });

// app.modules.styledProductSlider = slider.createInitInScope('.js-styled-product-slider', {
//     nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
//     prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
//     infinite: false,
//     slidesToShow: 2.5,
//     slidesToScroll: 1,
//     variableWidth: true,
//     responsive: [
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1
//             }
//         },
//     ]
// });

// app.modules.imgSlider = slider.createInitInScope('.js-img-slider', {
//     nextArrow: '<button type="button" class="btn-no-styling slick-next img-slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
//     prevArrow: '<button type="button" class="btn-no-styling slick-prev img-slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
//         arrows: true,
//         slidesToShow: 3,
//         responsive: [
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 1,
//                 }
//             }
//         ]
//     }
// );

// import * as videoJs from './scripts/videoJS';
// app.modules.videoJs = videoJs.initInScope;

// import * as loadVideo from './scripts/load-video';
// app.modules.loadVideo = loadVideo.initInScope;

// import * as transformIn from './scripts/transform-in';
// app.modules.transformIn = transformIn.initInScope;

// import * as productImgSlider from './scripts/product-img-slider';
// app.modules.productImgSlider = productImgSlider.initInScope;

//not found
// import * as productTeaserImgSlider from './scripts/product-teaser-img-slider';
// app.modules.productTeaserImgSlider = productTeaserImgSlider.initInScope;
//
// import * as changeProduct from './scripts/change-product';
// app.modules.changeProduct = changeProduct.initInScope;

//not found
// import * as getDeliveryStatus from './scripts/get-delivery-status';
// app.modules.getDeliveryStatus = getDeliveryStatus.initInScope;

// import * as tiltAnimation from './scripts/tilt-animation';
// app.modules.tiltAnimation = tiltAnimation.initInScope;

// import * as animationMoveUp from './scripts/animation-move-up';
// app.modules.animationMoveUp = animationMoveUp.initInScope;

// import * as animationFadeIn from './scripts/animation-fade-in';
// app.modules.animationFadeIn = animationFadeIn.initInScope;

// import * as animationScaleUp from './scripts/animation-scale-up';
// app.modules.animationScaleUp = animationScaleUp.initInScope;

// import * as showAllContent from './scripts/show-all-content';
// app.modules.showAllContent = showAllContent.initInScope;

// import * as discountItem from './scripts/discount-item';
// app.modules.discountItem = discountItem.initInScope;

// import * as toggleClick from './scripts/toggle-click';
// app.modules.toggleClick = toggleClick.initInScope;

// import * as productGrid from './scripts/product-grid/product-grid';
// app.productGrid = productGrid.init;
// app.modules.productGrid = productGrid.initInScope;

// import {closeOverlay} from "./scripts/overlay";

// close overlay when product grid response is successful
// $('.js-product-grid').on('product-grid.success', function(){
//     closeOverlay($('#productGridOverlay'));
// });


// import * as reloadForm from '@elements/reload-form';
// app.modules.reloadForm = reloadForm.initInScope;

// import * as productTeaser from './scripts/product-teaser';
// onInitModulesInScope(productTeaser.initInScope);

// import * as productGridQuickFilter from './scripts/product-grid/quick-filter';
// app.modules.productGridQuickFilter = productGridQuickFilter.initInScope;


// import * as filterCounter from './scripts/filter-counter';
// app.modules.filterCounter = filterCounter.initInScope;

// import * as cart from './scripts/cart';
// app.cart = cart.init;
// app.modules.cart = cart.initInScope;

//deprecated deleted
// import * as typeahead from './scripts/typeahead';
// app.modules.typeahead = typeahead.initInScope;

// import * as meiliSearchTypeahead from './scripts/search-typeahead';
// app.meiliSearchTypeahead = meiliSearchTypeahead.initInScope;

//not found
// import * as submitOnChange from './scripts/submit-on-change';
// app.modules.submitOnChange = submitOnChange.initInScope;

//deprecated deleted
// import * as dealerMap from './scripts/dealer-map';
// app.modules.dealerMap = dealerMap.initInScope;


//deprecated deleted
// import * as tabsWithAnchor from './scripts/tabs-with-anchor';
// app.modules.tabsWithAnchor = tabsWithAnchor.initInScope;

// import * as addFormSection from './scripts/add-form-section';
// app.addFormSection = addFormSection.init;

// import * as tooltip from './scripts/tooltip';
// app.modules.tooltip = tooltip.initInScope;

// import * as countrySelectModal from './scripts/country-select-modal';
// app.modules.countrySelectModal = countrySelectModal.initInScope;

// import * as informationModal from './scripts/information-modal';
// app.modules.informationModal = informationModal.initInScope;

// import * as formWithOverlay from './scripts/form-with-overlay';
// app.modules.formWithOverlay = formWithOverlay.initInScope;

//deprecated deleted
// import * as saveFormdata from './scripts/save-formdata';
// app.modules.saveFormdata = saveFormdata.initInScope;

//not found
// import * as preventSoldOutClick from './scripts/prevent-sold-out-click';
// app.modules.preventSoldOutClick = preventSoldOutClick.initInScope;

// import * as checkAddress from './scripts/check-address';
// app.modules.checkAddress = checkAddress.initInScope;

// import * as overlay from './scripts/overlay';
// app.modules.overlay = overlay.initInScope;

// import * as scrollDetect from './scripts/scroll-detect';
// app.modules.scrollDetect = scrollDetect.initInScope;

// import * as hotspot from './scripts/hotspot';
// app.modules.hotspot = hotspot.initInScope;


// import * as klarnaPayment from './scripts/klarna-payment';
// app.modules.klarnaPayment = klarnaPayment.initInScope;

// import * as waitForPayment from "./scripts/waitForPayment";
// app.waitForPayment = waitForPayment.init;

// import * as numberSpinner from "@elements/number-spinner";
// app.modules.numberSpinner = numberSpinner.initInScope;

// import * as footerCollapse from "./scripts/footer-collapse";
// app.modules.footerCollapse = footerCollapse.initInScope;

// import * as renderTemplate from './scripts/render-template';
// app.modules.renderTemplate = renderTemplate.initInScope;

//deprecated deleted
// import * as renderTemplateProducts from './scripts/render-template-products';
// app.modules.renderTemplateProducts = renderTemplateProducts.initInScope;

// import * as menuAim from './scripts/menu-aim';
// app.modules.menuAim = menuAim.initInScope;

// import * as collapsibleBreadcrumb from './scripts/collapsible-breadcrumb';
// app.modules.collapsibleBreadcrumb = collapsibleBreadcrumb.init;

// import * as molliePayment  from './scripts/mollie-payment';
// app.molliePayment = molliePayment.init;

// let selectors = {
//     link: '.js-smoothscroll__link'
// };
//
//
// import * as smoothScroll from '@elements/smooth-scroll';
// app.modules.smoothScroll = smoothScroll.init({
//     offset: 100
// },selectors);
//
// smoothScroll.initInScope();


// import * as anchorNav from './scripts/anchor-nav';
// app.modules.anchorNav  = anchorNav.createInitInScope({
//     list: '.js-anchor-nav',
//     listItem: '.js-anchor-nav__item'
// },  {
//     renderItem: function (text, anchor, icon) {
//         return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
//     },
//     smoothScroll: true,
//     scrollSpy: true,
//     scrollSpyOffset: 100
// });
//
//
// app.modules.anchorNavIcon  = anchorNav.createInitInScope({
//     list: '.js-content-anchor-nav__list',
//     listItem: '.js-content-anchor-nav__item'
// },  {
//     renderItem: function (text, icon, anchor) {
//         return '<li class="nav-item anchor-nav__item content-anchor-nav__item "><a class="d-flex flex-column js-smoothscroll__link" href="#' + anchor + '"><span class="icon icon-' + icon + ' content-anchor-nav__item-icon mb-2"></span><span class="btn-link-underline content-anchor-nav__item-title">' + text + '</span></a></li>';
//     },
//     smoothScroll: true,
//     scrollSpy: false,
//     scrollSpyTarget: '',
//     scrollSpyOffset: 10,
//     scrollSpyMethod: 'auto'
//
// });
//
// app.modules.anchorNavNumber  = anchorNav.createInitInScope({
//     list: '.js-magazine-anchor-nav__list',
//     listItem: '.js-magazine-anchor-nav__item'
// },  {
//     renderItem: function (text, icon, anchor) {
//         return '<li class="nav-item anchor-nav__item magazine-anchor-nav__item"><a class="js-smoothscroll__link" href="#' + anchor + '"><span class="magazine-link-underline">' + text + '</span></a></li>';
//     },
//     smoothScroll: true,
//     scrollSpy: false,
//     scrollSpyTarget: '',
//     scrollSpyOffset: 10,
//     scrollSpyMethod: 'auto'
// });

// import * as ajaxModal from './scripts/ajax-modal';
// app.modules.ajaxModal = ajaxModal.initInScope;

//deprecated deleted
// app.filterForm = function () {
//     import('./scripts/filter-form/filter-form').then(function (filterForm) {
//         app.modules.filterForm = filterForm.initInScope;
//
//         filterForm.init();
//         filterForm.initInScope($('body'));
//     });
// };
//
// app.filterFormMap = function () {
//     import('./scripts/filter-form/filter-form-map').then(function (filterFormMap) {
//         app.modules.filterFormMap = filterFormMap.initInScope;
//         app.filterFormMap = filterFormMap.init;
//
//         filterFormMap.init();
//         filterFormMap.initInScope($('body'));
//     });
// };

// import * as teaserSlider  from './scripts/teaserSlider';
// app.modules.teaserSlider = teaserSlider.initInScope;

// import * as applePay from "./scripts/apple-pay";
// app.modules.applePay = applePay.initInScope;

// import * as removeVoucher from './scripts/remove-voucher';
// app.modules.removeVoucher = removeVoucher.initInScope;

// import * as lottieAnimation from './scripts/lottie-animation';
// lottieAnimation.init();

// import * as dateInput from './scripts/date-input';
// app.modules.dateInput = dateInput.initInScope;

// import * as filterDropdown from './scripts/filter-form/filter-dropdown';
// app.modules.filterDropdown = filterDropdown.initInScope;

// import * as tracking from './scripts/tracking';
// app.modules.tracking = tracking.initInScope;

// import {initGtmEventTracking} from "./scripts/genericTracking";
// initGtmEventTracking();
//
// import * as trackingGa4 from './scripts/tracking-ga4';
// let ga4Options = {
//     debug: true
// }; // optional options
//
// app.modules.trackingGa4 = trackingGa4.initGtmEventTracking(ga4Options);
//
// import * as trackingCustom from './scripts/tracking-custom';
// app.modules.trackingCustom = trackingCustom.initInScope;
//
// import * as trackingErrorMessages from './scripts/tracking-error-messages';
// app.trackingErrorMessages = trackingErrorMessages.init;

// check if user reached bottom
$(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        $('body').addClass('is-bottom');
    }else{
        if($('body').hasClass('is-bottom')){
            $('body').removeClass('is-bottom');
        }
    }
});

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})(jQuery);

// import * as buyLocal from './scripts/buy-local-helper';
// app.buyLocal = buyLocal.init;
